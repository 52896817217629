import { collection, doc, getDoc, getFirestore, onSnapshot, query, where } from "firebase/firestore";

async function getServiceDoc(domainId) {
    const db = getFirestore();
    const serviceDoc = await getDoc(doc(db, "domains", domainId, "services", "commonsku"));
    return serviceDoc.data();
}

function getOrders(domainId, client_name, setOrders) {
    const db = getFirestore();
    const q = query(collection(db, "domains", domainId, "services", "commonsku", "orders"), where("client_name", "==", client_name));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let jobs = querySnapshot.docs.map((doc) => {return doc.data()});
        jobs = jobs.sort((a, b) => Number(a.job_number) - Number(b.job_number));
        setOrders(jobs);
    }, (error) => console.log(error));
    return unsubscribe;
}

export { getServiceDoc, getOrders };