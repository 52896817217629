import React from "react";
import * as CommonskuExportManager from "../managers/CommonskuExportManager";
import { Autocomplete, Container, Card, TextField, Stack, CircularProgress, CardContent, Typography, Alert, Button, createFilterOptions, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { DataGrid, renderActionsCell } from "@mui/x-data-grid";
import { render } from "@testing-library/react";
import moment from "moment";

function CommonskuExportPage() {
    const { domainId } = useParams();
    let [serviceData, setServiceData] = React.useState(null);
    let [orders, setOrders] = React.useState(null);
    let [clientName, setClientName] = React.useState();
    let [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setOrders(null);
        const getServiceData = async () => {
            return setServiceData(await CommonskuExportManager.getServiceDoc(domainId));
        };
        getServiceData()
    }, [domainId]);

    React.useEffect(() => {
        setOrders(null);
        const onComplete = (orders) => {
            setIsLoading(false);
            setOrders(orders)
        }
        if (clientName) {
            setIsLoading(true);
            return CommonskuExportManager.getOrders(domainId, clientName, onComplete);
        }
    }, [domainId, clientName]);

    const columns = [
        {
            field: 'job_number',
            headerName: 'Job #',
            flex: 1
        },
        {
            field: 'job_name',
            headerName: 'Job Name',
            flex: 3
        },
        {
            field: 'total_total',
            headerName: 'Total',
            align: 'right',
            flex: 1,
            valueFormatter: (params) => {
                return "$" + Number(params.value).toFixed(2);
            }
        },
        {
            field: 'date_orderdate',
            headerName: 'Order Date',
            flex: 2,
            valueFormatter: (params) => {
                return moment(params.value).format("MM/DD/YYYY");
            }
        },
        {
            field: 'order_id',
            headerName: 'View Order',
            renderCell: (props) => {
                return (
                    <Button href={"https://storage.googleapis.com/csku-data/ordersHtml/" + props.value} target="_blank">View</Button>
                )
            }
        }
    ]

    if (serviceData === null) {
        return (<CircularProgress />)
    }

    const defaultFilterOptions = createFilterOptions()

    return (
        <Container>
            <Card sx={{mb: 1, mt: 1}}>
                <CardContent>
                    <Typography sx={{mb: 2}} variant="h5">Filter</Typography>
                    <Autocomplete
                        id="business-name"
                        disableClearable
                        value={clientName ?? ""}
                        options={serviceData.clientNames}
                        getOptionLabel={(o) => o}
                        filterOptions={(options, state) => {
                            return defaultFilterOptions(options, state).splice(0, 100)
                        }}
                        renderInput={(params) => {
                            return (<TextField required {...params} label="Client Name" />);
                        }}
                        onChange={(event, value) => {
                            setClientName(value);
                        }}
                    />
                    <Alert sx={{mt: 1}} severity="info">Additional data can be made available upon request.</Alert>
                </CardContent>
            </Card>
            {isLoading && (
                <Box sx={{height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </Box> 
            )}
            {orders?.length > 0 && (
                <Card>
                    <Typography variant="h6" m={2}>Sales Orders</Typography>
                    <DataGrid 
                        columns={columns}
                        rows={orders}
                        getRowId={(row) => row.order_id}
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'job_number', sort: 'asc'}]
                            }
                        }}
                    />
                </Card>
            )}
        </Container>
    );
}

export default CommonskuExportPage;