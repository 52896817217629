import './App.css';
import NavigationBar from './NavigationBar';
import PricingPage from './PricingPage';
import DecoManagerPage from './Decoration/DecoManagerPage';
import InventoryPage from './InventoryPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import React, { useEffect, useState } from 'react';
import LoginPage from './LoginPage';
import { Box, CircularProgress, Container, createTheme, ThemeProvider } from '@mui/material';
import ImportManagerPage from './InksoftSyncoreImport/ImportManagerPage';
import StylePrintPage from './Decoration/StylePrintPage';
import { purple } from '@mui/material/colors';
import StoreAuditPage from './StoreAudit/StoreAuditPage';
import PaymentTerminalPage from './PaymentTerminal/PaymentTerminalPage';
import AdvanceProductToolsPage from './AdvanceProductTools/AdvanceProductToolsPage';
import InksoftAdvancePage from './InksoftAdvance/InksoftAdvancePage';
import ProductSync from './InksoftAdvance/ProductSync';
import DepositManagementPage from './Deposits/DepositManagementPage';
import EULA from './EULA';
import PrivacyPolicy from './PrivacyPolicy';
import InksoftCommissionPage from './InksoftCommissions/InksoftCommissionPage';
import SyncoreExportPage from './SyncoreExport/SyncoreExportPage';
import TaskManagementPage from './Admin/TaskManagementPage';
import CAReportPage from './Admin/CAReportPage';
import AdvanceSettingsPage from './Admin/AdvanceSettingsPage';
import ShipmentManagementPage from './Shipping/ShipmentManagementPage';
import PayoutManagementPage from './Payout/PayoutManagementPage';
import RecipientPage from './Payout/RecipientPage';
import BulkActionsPage from './BulkActions/BulkActionsPage';
import CommonskuExportPage from './CommonskuExport/CommonskuExportPage';
// import SanmarPage from './SanmarPage';

// const SERVICE_URL = "http://localhost:8080";
const SERVICE_URL = "https://threadpath-service-gs6thggkma-uc.a.run.app";

const firebaseConfig = {
  apiKey: "AIzaSyCE67ZZqK6o2wQR5z3myxNMT-Vbc_n4GUk",
  authDomain: "threadpath.firebaseapp.com",
  projectId: "threadpath",
  storageBucket: "threadpath.appspot.com",
  messagingSenderId: "442191446852",
  appId: "1:442191446852:web:3cbbc0f280966fa5a30d11",
  measurementId: "G-EK2NHVS9J6"
};

const mainTheme = createTheme({
  typography: {
    fontFamily: "Inter"
  },
  palette: {
    primary: purple,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px"
        }
      }
    },
  },
});

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const [user, setUser] = useState(null);

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  }, [auth]);

  if (user === null) {
    return (
      <ThemeProvider theme={mainTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
              path="/privacypolicy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="/eula"
              element={<EULA />}
            />
          </Routes>
        </BrowserRouter>
        <LoginPage />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<WithNavBar db={db} user={user} element={<HomePage user={user} db={db} />} />} />
          <Route path="/home" element={<WithNavBar db={db} user={user} element={<HomePage user={user} db={db} />} />} />
          <Route
            path="/privacypolicy"
            element={<WithNavBar db={db}user={user} element={<PrivacyPolicy />} />}
          />
          <Route
            path="/eula"
            element={<WithNavBar db={db}user={user} element={<EULA />} />}
          />
          <Route path="domains">
            <Route
              path=":domainId/pricing"
              element={<WithNavBar db={db} user={user} element={<PricingPage db={db} />} />}
            />
            <Route
              path=":domainId/inventory"
              element={<WithNavBar db={db} user={user} element={<InventoryPage db={db} />} />}
            />
            <Route
              path=":domainId/inksoftdecoration"
              element={<WithNavBar db={db} user={user} element={<DecoManagerPage db={db} />} />}
            />
            <Route
              path=":domainId/printstyles"
              element={<StylePrintPage db={db} />}
            />
            <Route
              path=":domainId/inksoftsyncoreimport"
              element={<WithNavBar db={db} user={user} element={<ImportManagerPage db={db} />} />}
            />
            <Route
              path=":domainId/inksoftadvanceorders"
              element={<WithNavBar db={db} user={user} element={<InksoftAdvancePage db={db} />} />}
            />
            <Route
              path=":domainId/inksoftadvanceproducts"
              element={<WithNavBar db={db} user={user} element={<ProductSync db={db} />} />}
            />
            <Route
              path=":domainId/depositmanagement"
              element={<WithNavBar db={db} user={user} element={<DepositManagementPage db={db} />} />}
            />
            <Route
              path=":domainId/storeaudit"
              element={<WithNavBar db={db} user={user} element={<StoreAuditPage db={db} />} />}
            />
            <Route
              path=":domainId/shipmentmanagement"
              element={<WithNavBar db={db} user={user} element={<ShipmentManagementPage db={db} />} />}
            />
            <Route
              path=":domainId/syncoreexport"
              element={<WithNavBar db={db} user={user} element={<SyncoreExportPage db={db} />} />}
            />
            <Route
              path=":domainId/commonskuexport"
              element={<WithNavBar db={db} user={user} element={<CommonskuExportPage db={db} />} />}
            />
            <Route
              path=":domainId/paymentterminal"
              element={<WithNavBar db={db} user={user} element={<PaymentTerminalPage db={db} />} />}
            />
            <Route
              path=":domainId/advanceproducttools"
              element={<WithNavBar db={db} user={user} element={<AdvanceProductToolsPage db={db} />} />}
            />
            <Route
              path=":domainId/inksoftcommission"
              element={<WithNavBar db={db} user={user} element={<InksoftCommissionPage db={db} />} />}
            />
            <Route
              path=":domainId/taskmanagement"
              element={<WithNavBar db={db} user={user} element={<TaskManagementPage db={db} />} />}
            />
            <Route
              path=":domainId/careport"
              element={<WithNavBar db={db} user={user} element={<CAReportPage db={db} />} />}
            />
            <Route
              path=":domainId/advancesettings"
              element={<WithNavBar db={db} user={user} element={<AdvanceSettingsPage db={db} />} />}
            />
            <Route
              path=":domainId/advanceorderbulkactions"
              element={<WithNavBar db={db} user={user} element={<BulkActionsPage db={db} />} />}
            />
            <Route
              path=":domainId/payoutmanagement"
              element={<WithNavBar db={db} user={user} element={<PayoutManagementPage db={db} />} />}
            />
            <Route
              path=":domainId/payoutmanagement/recipients/:recipientId"
              element={<WithNavBar db={db} user={user} element={<RecipientPage db={db} />} />}
            />
          </Route>
          {/* <Route path="/pricing" element={<PricingPage />} />
          <Route path="/inventory" element={<InventoryPage db={db} />} /> */}
          {/* <Route path="/salesorders" element={<CombineSOPage db={db} />} /> */}
          {/* <Route path="/decomanager" element={<DecoManagerPage db={db} />} /> */}
          {/* <Route path="/importmanager" element={<ImportManagerPage db={db} />} /> */}
          {/* <Route path="/pomanager" element={<POManagerPage db={db} />} /> */}
          {/* <Route path="/createBulkPO" element={<CreateBulkPage db={db} />} /> */}
          {/* <Route path="/sanmar" element={<SanmarPage />} /> */}
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

function WithNavBar(props) {
  const { db, user } = props;
  const [userData, setUserData] = useState(null);
  const [domains, setDomains] = useState(null);
  const [domainId, setDomainId] = useState(null);

  React.useEffect(() => {
    console.log(user);
    const getUserData = async () => {
      let userDoc = await getDoc(doc(db, "users", user.uid));
      setUserData(userDoc.data());
      setDomainId(userDoc.get('mainDomainId'));
    };
    if (user != null) {
      getUserData();
    }
  }, [db, user]);

  React.useEffect(() => {
    const getDomains = async () => {
      if (userData.superAdmin === true) {
        let domains = await getDocs(collection(db, "domains"));
        setDomains(domains.docs);
      } else {
        let domain = await getDoc(doc(db, "domains", userData.mainDomainId));
        setDomains([domain]);
      }
    };
    if (userData != null) {
      getDomains();
    }
  }, [db, userData]);

  const { element } = props;
  let match = domains?.filter(d => d.id === domainId) ?? [];
  let domain = null;
  if (match.length > 0) {
    domain = match[0].data();
  }
  return (
    <>
      <NavigationBar domainId={domainId} domains={domains} onSelectDomain={(id) => setDomainId(id)} />
      {
        domain === null &&
        <Box sx={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </Box>
      }
      {
        domain !== null &&
        <element.type {...element.props} domain={domain} domainId={domainId} />
      }
    </>
  )
}

export { App, SERVICE_URL };
