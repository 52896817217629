import React from "react";
import { Alert, Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { SERVICE_URL } from "../App";
import { useParams } from "react-router-dom";
import * as AdvanceManager from "../managers/AdvanceManager";

function UpdateOrderCard(props) {
    const { domainId } = useParams();
    let [orderNumber, setOrderNumber] = React.useState("");
    let [loading, setLoading] = React.useState(false);

    const onClick = async (id) => {
        try {
            setLoading(true);
            await AdvanceManager.updateAdvanceOrder(domainId, "Inksoft", id);
            setOrderNumber("");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card sx={{width: "600px", marginLeft: "auto", marginRight: "auto"}}>
            <CardContent>
                <Stack
                    direction={"column"}
                    gap={1}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Typography variant="h5" textAlign={"center"} mb={2}>Update Inksoft Order</Typography>
                    <TextField
                        id="outlined-number"
                        label="Order Number"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={orderNumber}
                        onChange={(event) => setOrderNumber(event.target.value)}
                    />
                    <Alert severity="warning" sx={{mt: 1, mb: 1}}>Currently this only adds new payments or refunds. It will not adjust items.</Alert>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        onClick={() => onClick(orderNumber)}
                        disabled={orderNumber === ""}
                    >
                        Update
                    </LoadingButton>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default UpdateOrderCard;