import { Card, Typography, CardContent, CardActionArea } from "@mui/material";

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export default function CAStoreDataCard({ name, storeData, shippingData }) {

    const productRevenue = storeData.productRevenue - storeData.staffDiscount - storeData.otherDiscount - storeData.commission - storeData.prepaidCost - (shippingData?.subsidy ?? 0);
    const productCost = storeData.estProductCost - (shippingData?.subsidy ?? 0);
    const productMargin = productRevenue - productCost;
    const productMarginPercent = productMargin / productRevenue * 100;

    const lessCommissionRevenue = productRevenue + (shippingData?.billedRevenue ?? 0) + (shippingData?.subsidy ?? 0)
    const lessCommissionCost = productCost + (shippingData?.billedCost ?? 0) + (shippingData?.subsidy ?? 0);
    const lessCommissionMargin = lessCommissionRevenue - lessCommissionCost;
    const lessCommissionMarginPercent = lessCommissionMargin / lessCommissionRevenue * 100;

    const totalRevenue = lessCommissionRevenue + storeData.commission + storeData.prepaidCost
    const totalCost = lessCommissionCost + storeData.commission + storeData.prepaidCost
    const margin = totalRevenue - totalCost;
    const marginPercent = margin / totalRevenue * 100;

    const netShipping = (shippingData?.billedRevenue ?? 0) - (shippingData?.billedCost ?? 0) + (shippingData?.billedSubsidy ?? 0);

    return (
        <Card variant="outlined">
            <CardActionArea onClick={() => navigator.clipboard.writeText([totalRevenue, totalCost, margin, lessCommissionRevenue, lessCommissionMarginPercent, productMarginPercent, netShipping].join(','))}>
                <CardContent>
                    <Typography variant="h6">{name}</Typography>
                    <Typography variant="body1">Revenue: {USDollar.format(totalRevenue)}</Typography>
                    <Typography variant="body1">Est Cost: {USDollar.format(totalCost)}</Typography>
                    <Typography variant="body1">Revenue less commission: {USDollar.format(lessCommissionRevenue)}</Typography>
                    <Typography variant="body1">Product: {USDollar.format(productRevenue)} {USDollar.format(productCost)}</Typography>
                    <Typography variant="body1">Total Est Cost less commission: {USDollar.format(lessCommissionCost)}</Typography>
                    <Typography variant="body1">Product Margin: {USDollar.format(productMargin)}{` (${productMarginPercent.toFixed(2)}%)`}</Typography>
                    <Typography variant="body1">Less Commission Margin: {USDollar.format(lessCommissionMargin)}{` (${lessCommissionMarginPercent.toFixed(2)}%)`}</Typography>
                    <Typography variant="body1">Margin: {USDollar.format(margin)}{` (${marginPercent.toFixed(2)}%)`}</Typography>
                    <Typography variant="body1">Orders: {storeData.orderCount}</Typography>
                    <Typography variant="body1">Net Billed Shipping: {USDollar.format(netShipping)}</Typography>
                    <Typography variant="body1">Commission: {USDollar.format(storeData.commission)}</Typography>
                    <Typography variant="body1">Prepaid Cost: {USDollar.format(storeData.prepaidCost)}</Typography>
                    <Typography variant="body1">Staff Discount: {USDollar.format(storeData.staffDiscount)}</Typography>
                    <Typography variant="body1">Other Discount: {USDollar.format(storeData.otherDiscount)}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}