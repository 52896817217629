import axios from "axios";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { SERVICE_URL } from "../App";

async function getServiceDoc(domainId) {
    const db = getFirestore();
    const serviceDoc = await getDoc(doc(db, "domains", domainId, "services", "advance"));
    return serviceDoc.data();
}

function getServiceDocSnapshot(domainId, setServiceDoc) {
    const db = getFirestore();
    let serviceRef = doc(db, "domains", domainId, "services", "advance");
    const unsubscribe = onSnapshot(serviceRef, (doc) => {
        console.log("Service doc updated");
        setServiceDoc(doc?.data() ?? {});
    }, (error) => console.log(error));
    return unsubscribe;
}

async function fixSanmarProductImages(domainId, productUrl) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    const productId = productUrl.substring(productUrl.lastIndexOf("/") + 1);
    let formData = new FormData();
    formData.append("productId", productId);
    let response = await axios.post(
        `${SERVICE_URL}/advance/fixSanmarProductImages`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.id;
}

async function getAccountArtwork(domainId, accountId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getAccountArtwork`,
        {
            params: {
                "domainId": domainId,
                "accountId": accountId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.artwork;
}

async function getAccounts(domainId, name) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getAccounts`,
        {
            params: {
                "domainId": domainId,
                "name": name,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.accounts;
}

async function getAccountContacts(domainId, accountId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getAccountContacts`,
        {
            params: {
                "domainId": domainId,
                "accountId": accountId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.contacts;
}

async function getAccount(domainId, accountId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getAccount`,
        {
            params: {
                "domainId": domainId,
                "id": accountId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.account;
}

async function getBulkActionsOrders(domainId, accountName, accountId, orderDateFrom, orderDateTo) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/bulkActions/getOrders`,
        {
            params: {
                "domainId": domainId,
                "accountName": accountName,
                "accountId": accountId,
                "orderDateFrom": orderDateFrom,
                "orderDateTo": orderDateTo,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.orders;
}

async function getCommissionGroups(domainId, name) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getCommissionGroups`,
        {
            params: {
                "domainId": domainId,
                "name": name,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.commissionGroups;
}

async function getProducts(domainId, search) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getProducts`,
        {
            params: {
                "domainId": domainId,
                "search": search,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.products;
}

async function getProductsByTag(domainId, tagIds) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getProducts`,
        {
            params: {
                "domainId": domainId,
                "tagIds": JSON.stringify(tagIds),
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.products;
}

async function getProduct(domainId, productId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getProduct`,
        {
            params: {
                "domainId": domainId,
                "id": productId
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.product;
}

async function getContact(domainId, contactId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getContact`,
        {
            params: {
                "domainId": domainId,
                "id": contactId
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.contact;
}

async function getOrderKanbanStatuses(domainId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getOrderKanbanStatuses`,
        {
            params: {
                "domainId": domainId
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.kanbanStatuses;
}

async function searchUsers(domainId, search) {
    if (search === "")
        return [];
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/searchUsers`,
        {
            params: {
                "domainId": domainId,
                "search": search,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.users;
}

async function getOrderImportPreview(domainId, source, orderIdentifiers) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getWebstoreOrderImportPreview`,
        {
            params: {
                "source": source,
                "orderIdentifiers": JSON.stringify(orderIdentifiers),
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.orders;
}

async function importWebstoreOrders(domainId, source, orderIdentifiers) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("source", source);
    formData.append("orderIdentifiers", JSON.stringify(orderIdentifiers));
    let response = await axios.post(
        `${SERVICE_URL}/advance/importWebstoreOrders`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.taskGroupIds;
}

async function updateAdvanceOrder(domainId, source, orderId) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("source", source);
    formData.append("orderId", orderId);
    await axios.post(
        `${SERVICE_URL}/advance/updateWebstoreOrder`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
}

async function createOrderBulkUpdateTasks(domainId, orderRefs, dueDate, shipDate, status, decorationUpdates, removeRowPrice, removeRowCost, removeDecoPrice, removeDecoCost) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.post(
        `${SERVICE_URL}/advance/bulkActions/createOrderBulkUpdateTasks`,
        {
            orderRefs: orderRefs,
            dueDate: dueDate,
            shipDate: shipDate,
            status: status,
            decorationUpdates: decorationUpdates,
            removeRowPrice: removeRowPrice,
            removeRowCost: removeRowCost,
            removeDecoPrice: removeDecoPrice,
            removeDecoCost: removeDecoCost
        },
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.taskGroupIds;
}

async function syncWebstoreAccount(domainId, source, storeIdentifier) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let formData = new FormData();
    formData.append("source", source);
    formData.append("storeIdentifier", storeIdentifier);
    let response = await axios.post(
        `${SERVICE_URL}/advance/syncWebstoreAccount`,
        formData,
        {
            params: {
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.account;
}

async function getProductSyncInfo(domainId, source, storeIdentifier) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    let response = await axios.get(
        `${SERVICE_URL}/advance/getProductSyncInfo`,
        {
            params: {
                "source": source,
                "storeIdentifier": storeIdentifier,
                "domainId": domainId,
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    );
    return response.data.data.products;
}

function getStoreSettingsDoc(domainId, storeUrl, setStoreSettings) {
    const db = getFirestore();
    let settingsDoc = doc(db, "domains", domainId, "services", "advance", "storeSettings", storeUrl);
    const unsubscribe = onSnapshot(settingsDoc, (doc) => {
        const settingsData = doc.data();
        if (!doc.exists()) {
            setDoc(settingsDoc, {});
        }
        setStoreSettings(settingsData ?? {});
    }, (error) => console.log(error));
    return unsubscribe;
}

async function updateStoreSettingsDoc(domainId, storeUrl, fieldPath, value) {
    const db = getFirestore();
    await updateDoc(doc(db, "domains", domainId, "services", "advance", "storeSettings", storeUrl), fieldPath, value);
}

async function updateServiceDataByPath(domainId, fieldPath, value) {
    const db = getFirestore();
    await updateDoc(doc(db, "domains", domainId, "services", "advance"), fieldPath, value);
}

async function updateServiceData(domainId, value) {
    const db = getFirestore();
    await updateDoc(doc(db, "domains", domainId, "services", "advance"), value);
}

export { updateAdvanceOrder, createOrderBulkUpdateTasks, getBulkActionsOrders, fixSanmarProductImages, getServiceDocSnapshot, getProductSyncInfo, getAccountArtwork, getAccounts, getOrderImportPreview, getServiceDoc, getStoreSettingsDoc, updateStoreSettingsDoc, searchUsers, importWebstoreOrders, getCommissionGroups, syncWebstoreAccount, getOrderKanbanStatuses, updateServiceDataByPath, updateServiceData, getProducts, getProduct, getProductsByTag, getAccount, getAccountContacts, getContact }; 