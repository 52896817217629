import React from "react";
import * as ShippingManager from "../managers/ShippingManager";
import * as TaskManager from "../managers/TaskManager";
import { Container, TableContainer, Paper, IconButton, Box, Typography, CircularProgress, TextField, Dialog, DialogTitle, DialogContent, Button, DialogActions, Stack } from '@mui/material';
import { useParams } from "react-router-dom";
import { Delete, Refresh } from "@mui/icons-material";
import { DataGrid } from '@mui/x-data-grid';
import { serverTimestamp } from "firebase/firestore";

const ShipmentManagementPage = () => {
    let { domainId } = useParams();
    const [shipments, setShipments] = React.useState(null);
    const [pending, setPending] = React.useState(null);
    const [selected, setSelected] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [advanceOrderNumber, setAdvanceOrderNumber] = React.useState("");
    const [dialogLoading, setDialogLoading] = React.useState(false);

    React.useEffect(() => {
        return ShippingManager.getPendingShipments(domainId, setShipments);
    }, [domainId]);

    React.useEffect(() => {
        const setTasks = (tasks) => {
            setPending(tasks.map((t) => t.data.shipmentIds).flat());
        }
        if (pending === null) {
            TaskManager.getPendingTasksWithFunction(domainId, "shipping/processShipments", setTasks);
        }
    }, [pending, domainId]);

    const handleRowUpdate = (updated, original) => {
        ShippingManager.updateShipment(domainId, updated.id, {"overrideAdvanceOrderNo": updated.overrideAdvanceOrderNo});
        return updated;
    }

    const handleCloseDialogUpdate = async () => {
        setDialogLoading(true);
        await ShippingManager.updateShipments(domainId, selected, {"overrideAdvanceOrderNo": advanceOrderNumber});
        setOpenDialog(false);
        setDialogLoading(false);
        setAdvanceOrderNumber("");
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setAdvanceOrderNumber("");
    }

    const handleProcessSelected = () => {
        TaskManager.createProcessShipmentsTask(domainId, selected);
    }

    const handleProcess = (id) => {
        TaskManager.createProcessShipmentsTask(domainId, [id]);
    }

    const handleDelete = (id) => {
        ShippingManager.updateShipments(domainId, [id], {"processed": true, "processedVoid": true, "processedDate": serverTimestamp(), "processedVoidDate": serverTimestamp()});
    }

    if (shipments === null) {
        return <Box sx={{marginLeft: "auto", marginRight: "auto", width: 100, height: "40vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CircularProgress />
        </Box>;
    }

    return (
        <Container sx={{ mt: 2, mb: 2 }}>
            <TableContainer component={Paper}>
                <Typography variant="h5" textAlign={"center"} m={2}>Pending Shipments</Typography>
                {
                    selected.length > 0 &&
                    <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
                        <Button onClick={() => setOpenDialog(true)}>Update Advance Order No.</Button>
                        <Button onClick={handleProcessSelected}>Process</Button>
                    </Box>
                }
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={shipments}
                        columns={[
                            { field: 'id', headerName: 'ID', width: 150 },
                            { field: 'referenceNumber', headerName: 'Reference No.', width: 200 },
                            { field: 'trackingNumber', headerName: 'Tracking No.', width: 200 },
                            { field: 'source', headerName: 'Source', width: 125 },
                            { field: 'createDate', headerName: 'Create Date', width: 125, valueFormatter: (params) => params.value.toDate().toLocaleDateString() },
                            { 
                                field: 'overrideAdvanceOrderNo',
                                headerName: 'Advance Order No.',
                                width: 150,
                                editable: true,
                                valueGetter: (params) => params.row.advanceOrderRef?.no ?? params.row.overrideAdvanceOrderNo ?? "",
                            },
                            {
                                field: 'actions', headerName: 'Actions', width: 150, renderCell: (params) => (
                                    pending?.includes(params.row.id) ? <CircularProgress size="2rem" /> :
                                        <Stack
                                            direction={"row"}
                                        >
                                            <IconButton aria-label="expand row" size="small" onClick={() => handleProcess(params.row.id)}>
                                                <Refresh />
                                            </IconButton>
                                            {
                                                params.row.processed === false && params.row.processedVoid === false &&
                                                <IconButton aria-label="expand row" size="small" onClick={() => handleDelete(params.row.id)}>
                                                    <Delete />
                                                </IconButton>
                                            }
                                        </Stack>
                                )
                            }
                        ]}
                        sx={{ border: 0 }}
                        hideFooter
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newSelection) => {
                            setSelected(newSelection);
                        }}
                        processRowUpdate={handleRowUpdate}
                        onProcessRowUpdateError={(error) => console.log(error)}
                        rowSelectionModel={selected}
                        isRowSelectable={(params) => !(pending?.includes(params.row.id) ?? true)}
                        localeText={{ noRowsLabel: "No pending shipments" }}
                    />
                </Box>
            </TableContainer>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Update Advance Order Number</DialogTitle>
                {
                    dialogLoading &&
                    <DialogContent>
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress/>
                        </Box>
                    </DialogContent>
                }
                {
                    !dialogLoading && 
                    <>                    
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Advance Order Number"
                                type="text"
                                value={advanceOrderNumber}
                                onChange={(e) => setAdvanceOrderNumber(e.target.value)}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Cancel</Button>
                            <Button onClick={handleCloseDialogUpdate} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </>
                }
            </Dialog>
        </Container>
    );
};

export default ShipmentManagementPage;
